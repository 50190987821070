export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "error_code_1001_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войдите в аккаунт"])},
        "error_code_1001_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после входа в аккаунт"])},
        "error_code_1001_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти ко входу"])},
        "error_code_1002_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните профиль"])},
        "error_code_1002_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после заполнения профиля"])},
        "error_code_1002_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в профиль"])},
        "error_code_1003_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ запрещен"])},
        "error_code_1003_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ в раздел запрещен"])},
        "error_code_1003_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На главную"])},
        "error_code_1004_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш отклик отправлен!"])},
        "error_code_1004_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ придет вам на электронную почту, которую вы указали при регистрации"])},
        "error_code_1004_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_1005_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для удаления компании свяжитесь с нами  по адресу info", "@", "clinmarket.ru"])},
        "error_code_1005_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "error_code_1005_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_1006_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация прошла успешно!"])},
        "error_code_1006_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полный функционал маркетплейса станет доступен после подтверждения места работы"])},
        "error_code_1006_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_1007_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])},
        "error_code_1007_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письмо для восстановления пароля отправлено на почту"])},
        "error_code_1007_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ок"])},
        "error_code_0_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уже состоите в этой компании"])},
        "error_code_0_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста выберите другую компанию"])},
        "error_code_0_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
        "error_code_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте компанию"])},
        "error_code_1_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раздел будет доступен после создания компании"])},
        "error_code_1_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])}
      }
    }
  })
}
