import { each } from 'lodash';
import { profile } from '@/core/requests/auth';

import Store from '@/store';

export function setPermissions(role) {
  const data = {
    super_admin: ['admins', 'logs'],
  };

  Store.commit('user/permissions', data[role] || []);
}

export function getPermissions() {
  const { permissions } = Store.state.user;

  return permissions;
}

export function check(name) {
  return getPermissions().includes(name);
}

export async function can(acl = null) {
  const token = localStorage.getItem('application.auth.token');
  if (token !== null) {
    Store.commit('user/token', token);

    const response = await profile();
    if (response.code === 200) {
      Store.commit('user/info', response.data);
      setPermissions(response.data.role);
    } else {
      Store.commit('user/info', null);
      Store.commit('user/token', null);
      localStorage.removeItem('application.auth.token');
    }
  }

  if (acl === null || typeof acl.rules === 'undefined') {
    return true;
  }

  const { info } = Store.state.user;

  let isCan = false;
  each(acl.rules, (rule) => {
    if (rule === '?') {
      isCan = info === null;
    } else if (rule === '@') {
      isCan = info !== null;
    } else {
      isCan = info !== null && info.role === rule;
    }

    return !isCan;
  });

  if (!isCan) {
    if (info === null) {
      Store.commit('application/errorCode', 1001);
    } else if (info.profile_completed === false) {
      Store.commit('application/errorCode', 1002);
    } else {
      Store.commit('application/errorCode', 1003);
    }
  } else if (isCan && acl.profileCompleted && info.profile_completed === false) {
    Store.commit('application/errorCode', 1002);
    isCan = false;
  }

  return isCan;
}
