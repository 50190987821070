import { call } from '@/core/services/http';

export async function profile() {
  return await call('GET', 'info');
}

export async function registration(attributes) {
  return await call('POST', 'registration', attributes);
}

export async function registrationPassword(attributes) {
  return await call('POST', 'registration/password', attributes);
}

export async function login(attributes) {
  return await call('POST', 'login', attributes);
}

export async function forgot(attributes) {
  return await call('POST', 'forgot', attributes);
}

export async function recovery(attributes) {
  return await call('POST', 'recovery', attributes);
}

export async function checkCode(code) {
  return await call('GET', `recovery/check/${code}`);
}

export async function logout() {
  return await call('DELETE', 'logout');
}

export async function passwordCheck(attributes) {
  return await call('POST', 'profile/password/check', attributes);
}

export async function passwordChange(attributes) {
  return await call('POST', 'profile/password/change', attributes);
}
