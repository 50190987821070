<template>
  <mask
    id="mask0_711_11310"
    style="mask-type:alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="16"
    height="16"
  >
    <path
      d="M8.00004 7.33333C9.4728 7.33333 10.6667 6.13943 10.6667 4.66667C10.6667 3.19391 9.4728 2
        8.00004 2C6.52728 2 5.33337 3.19391 5.33337 4.66667C5.33337 6.13943 6.52728 7.33333
        8.00004 7.33333Z"
      stroke="#161E2F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4 14V12.6667C4 11.9594 4.28095 11.2811 4.78105 10.781C5.28115 10.281 5.95942 10 6.66667
        10H9.33333C10.0406 10 10.7189 10.281 11.219 10.781C11.719 11.2811 12 11.9594 12 12.6667V14"
      stroke="#161E2F"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </mask>
  <g mask="url(#mask0_711_11310)">
    <rect width="16" height="16" fill="#5B3AEF"/>
  </g>
</template>

<script>
export default {
  name: 'ProfileIcon',
};
</script>
