<template>
  <router-view/>

  <ErrorModal/>

  <select class="section">
    ljalwjd
  </select>

  <select class="section section_red">
    <header class="header">

    </header>
  </select>

  <IPNotifications/>
</template>

<script>
import IPNotifications from '@/core/plugins/notification/IPNotification.vue';
import ErrorModal from '@/core/components/site/ErrorModal';

export default {
  components: {
    ErrorModal,
    IPNotifications,
  },
};
</script>

<style lang="scss">
@import "@/core/ui/assets/styles/application";

.section {
  color: #5B3AEF;
}

.section_red {
  color: #FF1F00;
}

.section__header {

}
</style>
