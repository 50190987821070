import Store from '@/store';

export function setError(message, code = null) {
  Store.commit('application/authMessage', message);
  Store.commit('application/errorCode', code);
}

export default {
  setError,
};
