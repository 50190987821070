import { createRouter, createWebHistory } from 'vue-router';
import { can } from '@/core/services/acl';

export function createDefaultRouter(routers, defaultRoute = null, params = {}) {
  const routes = [
    {
      path: '/',
      name: 'main',
      component: () => import('@/core/views/layouts/MainLayout.vue'),
      children: routers,
      meta: { defaultRoute },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/core/views/NotFound.vue'),
    },
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    if (to.meta.defaultRoute && to.meta.defaultRoute !== to.name && to.path === '/') {
      next({ name: to.meta.defaultRoute, params });
    }

    if (await can(to.meta.acl || null)) {
      next();
    } else if (typeof from.name === 'undefined') {
      next({ name: to.meta.redirect || defaultRoute, params });
    } else {
      next({ name: from.name });
    }
  });

  return router;
}

export default createDefaultRouter;
