import { createDefaultRouter } from '@/core/services/router';

export default createDefaultRouter([
  {
    path: 'data/:name',
    name: 'data-table',
    component: () => import('@/views/data/dataTable'),
  },
  {
    path: 'data/:name/:id',
    name: 'data-view',
    component: () => import('@/views/data/dataView'),
  },
  {
    path: 'data/:name/create',
    name: 'data-create',
    component: () => import('@/views/data/dataModify'),
  },
  {
    path: 'data/:name/:id/:sub',
    name: 'data-table-sub',
    component: () => import('@/views/data/dataTable'),
  },
  {
    path: 'data/:name/:id/update',
    name: 'data-update',
    component: () => import('@/views/data/dataModify'),
  },
], 'data-table', { name: 'studies' });
