<template>
  <IPModal v-model="isShow">
    <template v-slot:title>
      {{ t(`error_code_${errorCode}_title`) }}
    </template>
    {{ t(`error_code_${errorCode}_message`) }}

    <template v-slot:footer>
      <IPButton @click="handleAction" adaptive>
        {{ t(`error_code_${errorCode}_action`) }}
      </IPButton>
    </template>
  </IPModal>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

import IPModal from '@/core/components/ui/IPModal';
import IPButton from '@/core/components/ui/IPButton';

export default {
  name: 'ErrorModal',

  components: {
    IPModal,
    IPButton,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isShow = computed({
      get() {
        return store.state.application.errorCode !== null;
      },

      set(value) {
        if (value === false) {
          store.commit('application/errorCode', null);
        }
      },
    });

    const errorCode = computed(() => store.state.application.errorCode);

    const handleAction = () => {
      switch (errorCode.value) {
        case 1001:
          router.push({ name: route.name, query: { m: 'login' } });
          break;
        case 1002:
          router.push({ name: 'profile' });
          break;
        case 1003:
          router.push({ name: 'studies' });
          break;
        case 1:
          router.push({ name: 'profile' });
          break;
        default:
      }

      isShow.value = false;
    };

    return {
      t,
      handleAction,
      isShow,
      message: computed(() => store.state.application.authMessage),
      errorCode,
    };
  },
};
</script>

<i18n>
{
  "ru": {
    "error_code_1001_title": "Войдите в аккаунт",
    "error_code_1001_message": "Раздел будет доступен после входа в аккаунт",
    "error_code_1001_action": "Перейти ко входу",

    "error_code_1002_title": "Заполните профиль",
    "error_code_1002_message": "Раздел будет доступен после заполнения профиля",
    "error_code_1002_action": "Перейти в профиль",

    "error_code_1003_title": "Доступ запрещен",
    "error_code_1003_message": "Доступ в раздел запрещен",
    "error_code_1003_action": "На главную",

    "error_code_1004_title": "Ваш отклик отправлен!",
    "error_code_1004_message": "Ответ придет вам на электронную почту, которую вы указали при регистрации",
    "error_code_1004_action": "Ок",

    "error_code_1005_title": "Для удаления компании свяжитесь с нами  по адресу info{'@'}clinmarket.ru",
    "error_code_1005_message": "",
    "error_code_1005_action": "Ок",

    "error_code_1006_title": "Регистрация прошла успешно!",
    "error_code_1006_message": "Полный функционал маркетплейса станет доступен после подтверждения места работы",
    "error_code_1006_action": "Ок",

    "error_code_1007_title": "Отправлено",
    "error_code_1007_message": "Письмо для восстановления пароля отправлено на почту",
    "error_code_1007_action": "Ок",

    "error_code_0_title": "Вы уже состоите в этой компании",
    "error_code_0_message": "Пожалуйста выберите другую компанию",
    "error_code_0_action": "Закрыть",

    "error_code_1_title": "Создайте компанию",
    "error_code_1_message": "Раздел будет доступен после создания компании",
    "error_code_1_action": "Создать"
  }
}
</i18n>
